import React from "react";
import ReactDOM from "react-dom";
import { deleteData, updateData } from "../../service/DatabaseTransactions";
import "../../Styles/Modal.css";
import "../../Styles/Summary.css";
import { triggerSalesChange } from "../../Utils/monitorChange.util";
import ModalComponent from "./ModalComponent";
import { db, doc } from "../../Utils/firebase";
import { getDocumentId } from "../../Utils/local-storage";
function DeleteModal({
  isShowingDelete,
  branch,
  id,
  hide,
  updateData: refresh,
  spareParts,
}) {
  const handleDelete = (e) => {
    e.preventDefault();
    const docRef = doc(db, branch, getDocumentId());

    var indexNum = 0;
    const tempData = [...spareParts];

    tempData.forEach((Element, index) => {
      if (Element.id === id) {
        indexNum = index;
      }
    });

    tempData.splice(indexNum, 1);
    updateData({ data: tempData }, docRef);
    refresh();
    hide();
  };
  const handleClose = () => {
    refresh();
    hide();
  };
  return isShowingDelete
    ? ReactDOM.createPortal(
        <React.Fragment>
          <ModalComponent
            handleSave={handleDelete}
            title="Are you sure you want to delete this product?"
            handleClose={handleClose}
            btnTitle="Delete"
          ></ModalComponent>
        </React.Fragment>,
        document.body
      )
    : null;
}

export default DeleteModal;

import React from "react";
import { useState } from "react";
import {
  getBranch,
  getCart,
  getDocumentId,
  getSalesId,
  storeCart,
  storeSalesId,
} from "../../Utils/local-storage";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { useEffect } from "react";
import {
  getDataAndDocumentId,
  uploadToBbConditionally,
} from "../../service/DatabaseTransactionHelper";
import { useNavigate } from "react-router-dom";
import Receipt from "./receipts/Receipt";
import { PDFViewer } from "@react-pdf/renderer";
import { updateData } from "../../service/DatabaseTransactions";
import { doc } from "firebase/firestore";
import { db } from "../../Utils/firebase";
function CheckOut({ spareParts, updateCart: refresh }) {
  const [showSaleOnCredit, setShowSaleOnCredit] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const navigate = useNavigate();
  const [isShowingReceipt, setIsShowingReceipt] = useState(false);
  const [receiptCart, setReceiptCart] = useState({});
  const [CheckOutItems, setCheckOutItems] = useState({
    buyersName: "",
    id: uuid(),
    Date: moment().format("YYYY-MM-DD HH:mm"),
    productPurchased: getCart(),
    credit: false,
    totalAmount: totalCost,
    creditAmountPaid: [],
    CreditPaymentNumber: 0,
    initialDeposit: 0,
    phoneNumber: "",
  });

  const [salesList, setSalesList] = useState([]);

  useEffect(() => {
    storeSalesId("");
    getDataAndDocumentId(getBranch() + "sales", storeSalesId, setSalesList);
  }, []);

  useEffect(() => {
    if (CheckOutItems.credit) {
      setShowSaleOnCredit(true);
    }
  }, [CheckOutItems]);

  const paymentOption = [
    { label: "Full Payment", value: false },
    {
      label: "Credit Payment",
      value: true,
    },
  ];

  useEffect(() => {
    const totalSales = Object.values(getCart())?.reduce(
      (total, salesAmount) =>
        total +
        parseInt(salesAmount.price, 10) * parseInt(salesAmount.quantity, 10),
      0
    );
    setTotalCost(totalSales);
    setCheckOutItems({
      ...CheckOutItems,
      totalAmount: totalSales,
    });
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    const value = e.target.value;
    setCheckOutItems({
      ...CheckOutItems,
      [e.target.name]: value,
    });

    if (CheckOutItems.credit) {
      setCheckOutItems((previouseCheckOutItems) => ({
        ...previouseCheckOutItems,
        CreditPaymentNumber: 0,
        creditAmountPaid: [
          {
            date: moment().format("YYYY-MM-DD HH:mm"),
            amount: previouseCheckOutItems.initialDeposit,
            paymentNumber: 0,
          },
        ],
      }));
    }
  };

  const ReduceTheStock = () => {
    let tempStock = [...spareParts];
    let index = 0;
    const docRef = doc(db, getBranch(), getDocumentId());
    Object.values(getCart())?.forEach((spt) => {
      tempStock?.map((sptStock, ind) => {
        if (sptStock.id === spt.id) {
          sptStock.quantity =
            parseInt(sptStock?.quantity) - parseInt(spt?.quantity);
          index = ind;
        }
      });
    });

    updateData({ doc: tempStock }, docRef);
  };
  const toggleIsShowingReceipts = () => {
    setIsShowingReceipt(!isShowingReceipt);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    ReduceTheStock();
    let tempSalesLlist = [...salesList];
    uploadToBbConditionally(
      CheckOutItems,
      tempSalesLlist,
      getSalesId(),
      getBranch() + "sales"
    );
    setReceiptCart(getCart());
    storeCart({});

    refresh();
    toggleIsShowingReceipts();
  };

  return (
    <>
      {isShowingReceipt ? (
        <Receipt CheckOutItems={CheckOutItems} receiptCart={receiptCart} />
      ) : (
        <div>
          <form className="w-50">
            <div className="form-group my-2">
              <label htmlFor="buyerName">Buyer's Name</label>
              <input
                type="text"
                className="form-control"
                id="buyersName"
                onChange={handleChange}
                name="buyersName"
                required
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="buyerName">Buyer's Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="buyersName"
                onChange={handleChange}
                name="phoneNumber"
                required
              />
            </div>

            <div className="form-group my-2">
              <label htmlFor="buyerName">Total Amount</label>
              <input
                type="number"
                className="form-control"
                id="totalAmount"
                name="totalAmount"
                value={totalCost}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="paymentOption">Payment Option</label>

              <select
                className="form-control"
                id="paymentOption"
                onChange={handleChange}
                name="credit"
              >
                {paymentOption.map((paymentopt, index) => (
                  <option key={index} value={paymentopt.value}>
                    {paymentopt.label}
                  </option>
                ))}
              </select>
            </div>
            {showSaleOnCredit ? (
              <>
                <div className="form-group">
                  <label>Initial deposit</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={handleChange}
                    name="initialDeposit"
                    required
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </form>
          <div className="d-flex justify-content-end mt-5">
            <button
              onClick={handleSubmit}
              /* type="submit" */
              className="btn btn-primary "
            >
              Checkout
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CheckOut;

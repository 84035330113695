import React, { useState } from "react";
import ReactDOM from "react-dom";
import ModalComponent from "../Modal/ModalComponent";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { db, doc } from "../../Utils/firebase";
import {
  uploadToDatabase,
  updateData,
} from "../../service/DatabaseTransactions";
import { getBranch, getExpensesId } from "../../Utils/local-storage";
function AddExpenses({
  hide,
  expensesList,
  updateData: refresh,
  expensesRefId,
}) {
  const [expensesDetails, setExpensesDetails] = useState({
    name: "",
    amount: 0,
    purpose: "",
    id: uuid(),
    Date: moment().format("YYYY-MM-DD HH:mm"),
  });
  const handleClose = () => {
    hide();
  };
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setExpensesDetails({
      ...expensesDetails,
      [e.target.name]: value,
    });
  };
  const handleSubmit = () => {
    let tempExpenses = [...expensesList];
    tempExpenses.push(expensesDetails);

    if (expensesRefId === "" || getExpensesId === undefined) {
      uploadToDatabase(getBranch() + "expenses", tempExpenses);
    } else {
      const expensesRef = doc(db, getBranch() + "expenses", expensesRefId);
      updateData({ data: tempExpenses }, expensesRef);
    }
    refresh();
    hide();
  };
  return ReactDOM.createPortal(
    <React.Fragment>
      <ModalComponent
        handleClose={handleClose}
        handleSave={handleSubmit}
        btnTitle="Save"
        title={"Add Expenses"}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group my-2">
            <label className="col-sm-2"> Name</label>
            <div className="col-12">
              <input
                type="text"
                className="form-control"
                onChange={handleChange}
                name="name"
                value={expensesDetails.name}
              />
            </div>
          </div>

          <div className="form-group my-2">
            <label className="col-sm-2">Amount</label>
            <div className="col-12">
              <input
                type="number"
                className="form-control"
                onChange={handleChange}
                name="amount"
                value={expensesDetails.amount}
              />
            </div>
          </div>

          <div className="form-group my-2">
            <label className="col-sm-2">Purpose</label>
            <div className="col-12">
              <textarea
                type="text"
                className="form-control"
                onChange={handleChange}
                name="purpose"
                value={expensesDetails.purpose}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    </React.Fragment>,
    document.body
  );
}

export default AddExpenses;

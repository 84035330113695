import { useState } from "react";
const UseModal = () => {
  const [isShowingAdd, setIsShowingAdd] = useState(false);
  const [isShowingSale, setIsShowingSale] = useState(false);
  const [isShowingEdit, setIsShowingEdit] = useState(false);
  const [isShowingDelete, setIsShowingDelete] = useState(false);
  const [isShowingAddUser, setIsShowingAddUser] = useState(false);
  const [isShowingAddExpense, setIsShowingAddEpense] = useState(false);
  const [isShowingPickDate, setIsShowingPickDate] = useState(false);
  function togglePickDate() {
    setIsShowingPickDate(!isShowingPickDate);
  }
  function toggleAdd() {
    setIsShowingAdd(!isShowingAdd);
  }
  function toggleSale() {
    setIsShowingSale(!isShowingSale);
  }

  function toggleEdit() {
    setIsShowingEdit(!isShowingEdit);
  }

  function toggleDelete() {
    setIsShowingDelete(!isShowingDelete);
  }
  function toggleAddUser() {
    setIsShowingAddUser(!isShowingAddUser);
  }
  function toggleAddExpenses() {
    setIsShowingAddEpense(!isShowingAddExpense);
  }

  return {
    isShowingAdd,
    toggleAdd,
    isShowingSale,
    toggleSale,
    isShowingEdit,
    toggleEdit,
    isShowingDelete,
    toggleDelete,
    isShowingAddUser,
    toggleAddUser,
    toggleAddExpenses,
    isShowingAddExpense,
    togglePickDate,
    isShowingPickDate,
  };
};
export default UseModal;

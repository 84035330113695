import React, { useState } from "react";
import "../Styles/Dashboard.css";

function Dashboard({ role, setBranch }) {
  return (
    <div>
      <div className="mt-4 mb-4">
        <h1 className="text-center mb-3">Welcome</h1>
      </div>
    </div>
  );
}

export default Dashboard;

import React from "react";
import { useState } from "react";
import { FrameEditCredit } from "../DatePicker/Frame";
import Payment from "./payment/Payment";

function EditCreditor({
  toggleShowCreditorsItem,
  creditor,
  soldItems,
  fetchData,
}) {
  const [showPayment, setShowPayemnt] = useState(true);
  const handleBack = (e) => {
    e.preventDefault();
    toggleShowCreditorsItem();
  };
  const toggleShowPayment = () => {
    setShowPayemnt(!showPayment);
  };
  const handlePayment = () => {
    toggleShowPayment();
  };
  return (
    <>
      {showPayment ? (
        <div className="conrtainer">
          <div>
            <h1>{creditor.buyersName}</h1>
          </div>
          <div className="m-4">
            <table className="table fw-bold">
              <thead className="bg-muted">
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(creditor?.productPurchased).map(
                  (prdPurch, index) => (
                    <FrameEditCredit key={index} productPurchased={prdPurch} />
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn bg-success m-4 text-light "
              onClick={handleBack}
            >
              Back
            </button>

            <button
              onClick={handlePayment}
              className="btn bg-success m-4 text-light"
            >
              Payment
            </button>
          </div>
        </div>
      ) : (
        <Payment
          toggleShowPayment={toggleShowCreditorsItem}
          creditor={creditor}
          soldItems={soldItems}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default EditCreditor;

import React from "react";

export default function Title({
  branch,
  btn,
  handleClick,
  input,
  handleSearch,
  showBtn,
  handleShowBtn,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    handleSearch(e.target.value);
  };
  return (
    <div>
      <div className="d-flex flex-row justify-content-between my-3">
        <div className="fw-bold"> {branch ? branch : "Branch"}</div>

        {btn ? (
          <button className="btn btn-success text-black" onClick={handleClick}>
            <span className="me-4">
              <i className="bi bi-plus" />
            </span>
            Add
          </button>
        ) : showBtn ? (
          <button className="btn btn-success text-black" onClick={handleClick}>
            <span className="me-4">
              <i className="bi bi-display" />
            </span>
            Pick Date
          </button>
        ) : (
          " "
        )}
      </div>
      <div className="d-flex flex-row justify-content-end my-3 mb-2">
        {input ? (
          <div className="form-group has-search w-25">
            <span className="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={handleChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

import React from "react";

function BranchUnavailable({ messagedisplayed }) {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 ">
      <b> {messagedisplayed}</b>
    </div>
  );
}

export default BranchUnavailable;

import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment/moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReceiptFrame from "./ReceiptFrame";

function Receipt({ CheckOutItems }) {
  const [date, setDate] = useState(moment().format("DD-MM-YYYY HH:MM:SS"));
  const navigate = useNavigate();
  const styles = StyleSheet.create({
    body: {
      padding: 10,
    },
    innerBody: {
      borderStyle: "dotted",
      borderWidth: 5,
      padding: 10,
    },
    table: {
      display: "table",
      width: "50%",
      //borderTop: 1,
      borderStyle: "solid",
      //borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: 100,
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1,
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    brand: {
      marginLeft: "auto",
      marginRight: "auto",
      color: "red",
    },
    dealers: {
      color: "green",
      fontSize: 10,
      marginLeft: 150,
      marginRight: 150,
    },
    brandContainer: {
      width: "100%",
      borderStyle: "inset",
      border: 5,
    },
    pagedisplay: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      height: "80%",
    },
    address: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "end",
      marginTop: 20,
    },
    itemAddres: {
      width: 150,
      overflow: "auto",
    },
    itemLeft: {
      padding: 4,
      fontSize: 10,
      flex: 1,
      overflow: "auto",
      width: 10,
    },
    title: {
      fontSize: 10,
    },
  });
  const handleClose = (e) => {
    e.preventDefault();
    navigate("/Stock");
  };

  useEffect(() => {
    console.log({ CheckOutItems });
  }, []);
  return (
    <div className="h-100">
      <PDFViewer style={styles.pagedisplay}>
        <Document>
          <Page size="A4" style={styles.body}>
            <View style={styles.innerBody}>
              <View>
                <View style={styles.brandContainer}>
                  <Text style={styles.brand}>
                    {"NAKUFLOWS MOTORSPARES LTD "}
                  </Text>
                  <Text style={styles.dealers}>
                    Dealer in:Ex and New-Japan Genuine spares For: Isuzu,
                    4,3,NQR,Mitsubishi,FH,Fuso, Canter, MK210 ,FRR, Dyna,
                    Mitsubishi F1 and Hino 500
                  </Text>
                </View>
                <View style={styles.title}>
                  <Text>Buyers Name: {CheckOutItems.buyersName}</Text>
                  <Text>Date of Purchase: {date}</Text>
                  <Text>
                    Payment method:{!CheckOutItems.credit ? "Cash" : "Credit"}
                  </Text>

                  {CheckOutItems.credit ? (
                    <>
                      <Text>
                        Total amount paid:{"Ksh "}
                        {parseInt(CheckOutItems.initialDeposit)}
                      </Text>
                      <Text>
                        Remaining amount:
                        {"Ksh "}
                        {parseInt(CheckOutItems.totalAmount) -
                          parseInt(CheckOutItems.initialDeposit)}
                      </Text>
                    </>
                  ) : (
                    <Text>
                      Total amount paid: {"Ksh "} {CheckOutItems.totalAmount}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Item No:</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Name</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Price </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Quantity</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Total Price</Text>
                  </View>
                </View>
                {Object.values(CheckOutItems.productPurchased)?.map(
                  (product, key) => (
                    <ReceiptFrame
                      styles={styles}
                      product={product}
                      key={key}
                      index={key}
                    />
                  )
                )}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Total</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}> </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}></Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {CheckOutItems.totalAmount}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.address}>
                <View style={styles.itemLeft}>
                  <View style={styles.itemAddres}>
                    <View style={styles.itemAddres}></View>
                    <Text>Kisumu:</Text>
                    <Text>Nyataya Road Next to Dubai Accessories Kisumu</Text>
                    <Text>0114 238 368</Text>
                  </View>
                </View>
                <View style={styles.itemLeft}>
                  <View style={styles.itemAddres}>
                    <Text>Nakuru:</Text>
                    <Text>
                      Watende Rd, Opp, Ghambra Motors & Engineering Nakuru
                    </Text>
                    <Text>0724 834 796 {"   "}0780 834 796</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      <div className="d-flex justify-content-end" style={{ width: "80%" }}>
        <button onClick={handleClose} className="btn btn-success mt-4 ">
          Close
        </button>
      </div>
    </div>
  );
}

export default Receipt;

import React from "react";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { showNotification } from "../../Utils/notification.util";
import { storeBranch, storeCart } from "../../Utils/local-storage";
import "../../Styles/Navbar.css";
function NavbarTop({ role, setBranch, cartLength }) {
  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        showNotification("Sign out successfuly", "success");
      })
      .catch((error) => {
        showNotification("An error occured while signing Out");
      });
  };
  const branches = [
    { name: "Nakuru", value: "nakuru" },
    { name: "Kisumu", value: "kisumu" },
  ];

  const handleChange = (e) => {
    e.preventDefault();
    setBranch("");
    storeCart({});
    if (role === "kisumu" || role === "nakuru") {
      // storeBranch(e.target.value);
      setBranch(role);
    } else {
      setBranch(e.target.value);
      storeBranch(e.target.value);
    }
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light w-100">
      <ul className="navbar-nav d-flex justify-content-between w-100">
        <li className="nav-item">
          <Link className="navbar-brand mx-4" to="/">
            Everflows
          </Link>
        </li>
        <li className="nav-item">
          {role === "admin" ? (
            <select
              name="branches"
              className="form-select"
              onChange={handleChange}
            >
              <option selected disabled>
                Select branch
              </option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.value}>
                  {branch.name}
                </option>
              ))}
            </select>
          ) : role === "kisumu" ? (
            <select
              name="branches"
              className="form-select"
              onChange={handleChange}
            >
              <option selected disabled>
                Select branch
              </option>
              <option disabled value="nakuru">
                Nakuru
              </option>
              <option value="kisumu">Kisumu</option>
            </select>
          ) : (
            <select
              name="branches"
              className="form-select"
              onChange={handleChange}
            >
              <option selected disabled>
                Select branch
              </option>
              <option value="nakuru">Nakuru</option>
              <option disabled value="kisumu">
                Kisumu
              </option>
            </select>
          )}
        </li>
        <li className="nav-item">
          <div className="d-flex justify-content-end align-items-centre">
            <Link to="/cart " className="cart-link  ">
              <h1>
                <i className="bi bi-cart"></i>
                <sup>{cartLength}</sup>
              </h1>
            </Link>

            <button onClick={logout} className=" btn btn-success ms-3">
              {" "}
              Sign out
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default NavbarTop;

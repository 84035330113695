import React from "react";
import { Text, View } from "@react-pdf/renderer";

function ReceiptFrame({ styles, product, index }) {
  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{index + 1}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product.name}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product.price}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product.quantity}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>
          {parseInt(product.price) * parseInt(product.quantity)}
        </Text>
      </View>
    </View>
  );
}

export default ReceiptFrame;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CartBody from "../components/cart/CartBody";
import { getCart } from "../Utils/local-storage";
import { showNotification } from "../Utils/notification.util";

function Cart({ updateCart, spareParts }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getCart()) {
      navigate("/");
      showNotification("Cart is empty!");
    }
  }, []);
  return (
    <div>
      <CartBody updateCart={updateCart} spareParts={spareParts} />
    </div>
  );
}

export default Cart;

import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cart from "../../pages/Cart";
import { getCart, storeCart } from "../../Utils/local-storage";
import CartFrame from "./CartFrame";
import EditCart from "./EditCart";

function CartBody({ updateCart: refresh, spareParts }) {
  const navigate = useNavigate();
  const handleRemove = (id) => {
    let tempCart = getCart();
    delete tempCart[id];
    storeCart({ ...tempCart });
    refresh();
  };
  const [itemsSold, setItemSold] = useState();
  const [showCartItems, setShowCartItems] = useState(true);

  const handleCheckOut = (e) => {
    e.preventDefault();
    navigate("/checkout");
  };
  const togggleShowCartItems = (soldItem) => {
    setShowCartItems(!showCartItems);
    setItemSold(soldItem);
  };

  return (
    <div>
      {showCartItems ? (
        <div>
          <table className="table fw-bold ">
            <thead className="bg-muted">
              <tr>
                <th> Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="overflow-auto">
              {Object.values(getCart())?.map((soldItem, index) => (
                <CartFrame
                  soldItem={soldItem}
                  key={index}
                  index={index}
                  handleRemove={handleRemove}
                  togggleShowCartItems={togggleShowCartItems}
                />
              ))}
            </tbody>
          </table>
          <div>
            <div className="d-flex justify-content-end">
              <button onClick={handleCheckOut} className="btn btn-primary">
                Check out
              </button>
            </div>
          </div>
        </div>
      ) : (
        <EditCart
          togggleShowCartItems={togggleShowCartItems}
          soldItem={itemsSold}
          spareParts={spareParts}
        />
      )}
    </div>
  );
}

export default CartBody;

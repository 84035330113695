import React from "react";

function ExpenseFrame({ expensesDetails }) {
  return (
    <tr>
      <td>{expensesDetails.name}</td>
      <td>{expensesDetails.purpose}</td>
      <td>{expensesDetails.Date}</td>
      <td>Ksh {expensesDetails.amount}</td>
    </tr>
  );
}

export default ExpenseFrame;

import React from "react";
import { Link, Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import NavbarTop from "../components/Navbar/NavbarTop";
import "../Styles/Layout.css";
import EditIcon from "../images/icons/edit.png";
import HomeIcon from "../images/icons/home.png";
import SettingIcon from "../images/icons/setting.png";
import SaleIcon from "../images/icons/sale.png";
import SummaryIcon from "../images/icons/summary.png";
import ExpensesIcon from "../images/icons/expenses.png";

function Layout({ role, cartLength, setBranch }) {
  return (
    <>
      <div>
        <div className="content-wrapper">
          <NavbarTop
            role={role}
            setBranch={setBranch}
            cartLength={cartLength}
          />

          <div className="main-display">
            <div className="side-navigation ">
              {/*   <div className="col-sm-3 side-navigation font-weight-bold"> */}
              <ol>
                <li>
                  <img src={HomeIcon} alt="" /> <Link to="/">Home</Link>
                </li>
                <li>
                  <img src={SaleIcon} alt="" />
                  <Link to="/stock">Sales</Link>
                </li>
                <li>
                  <img src={ExpensesIcon} alt="" />
                  <Link to="/expenses">Expenses</Link>
                </li>
                <li>
                  <img src={EditIcon} alt="" />
                  <Link to="/creditors">Creditor</Link>
                </li>
                {role === "admin" && (
                  <>
                    <li>
                      <img src={EditIcon} alt="" />
                      <Link to="/management">Operation</Link>
                    </li>

                    <li>
                      <img src={SummaryIcon} alt="" />
                      <Link to="/summary">Summary</Link>
                    </li>
                    <li>
                      <img src={SettingIcon} alt="" />
                      <Link to="/settings">Settings</Link>
                    </li>
                  </>
                )}
              </ol>
            </div>
            <div className="main-component">
              <Outlet />
            </div>
          </div>
          <div className="mt-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;

import { doc } from "firebase/firestore";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { updateData } from "../../service/DatabaseTransactions";
import { db } from "../../Utils/firebase";
import { getBranch, getDocumentId } from "../../Utils/local-storage";
import { showNotification } from "../../Utils/notification.util";

function AddNewStock({
  sparePart,
  toggleAddNewStock,
  spareParts,
  updateData: refresh,
}) {
  const [selectedSparePart, setSelectedSparePart] = useState({});
  const [newStock, setNewStock] = useState([]);
  const [totalStock, setTotalStock] = useState(0);
  const [oldStock, setOldStock] = useState(0);
  useEffect(() => {
    setSelectedSparePart(sparePart);
    console.log(sparePart);
    if (spareParts.stockProgress) setNewStock(...sparePart.stockProgress);
    if (sparePart.quantity) setOldStock(parseInt(sparePart.quantity));
  }, []);
  useEffect(() => {
    if (selectedSparePart.quantity)
      setTotalStock(parseInt(oldStock) + parseInt(selectedSparePart.quantity));
  }, [selectedSparePart.quantity]);

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSelectedSparePart({
      ...selectedSparePart,
      [e.target.name]: value,
    });
  };
  const handleClose = () => {
    toggleAddNewStock();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const docRef = doc(db, getBranch(), getDocumentId());
    let tempStockProgress = [...newStock];
    let tempSelectedSparePart = { ...selectedSparePart };
    tempStockProgress.push({
      data: moment().format("YYYY-MM-DD HH:mm"),
      quantity: selectedSparePart.quantity,
    });
    tempSelectedSparePart.stockProgress = tempStockProgress;
    tempSelectedSparePart.quantity = totalStock;

    let indexNum = 0;
    spareParts?.map((spt, index) => {
      if (spt.id === selectedSparePart.id) {
        indexNum = index;
      }
    });
    let tempData = [...spareParts];

    tempData[indexNum] = tempSelectedSparePart;

    updateData({ data: tempData }, docRef);
    console.log(tempData[indexNum]);
    refresh();
    showNotification("New Stock Updated", "success");
    toggleAddNewStock();
  };
  return (
    <div>
      <b>{sparePart.name}</b>

      <div className=" h-100 d-flex align-items-center justify-content-center m-2">
        <div className="w-50">
          <div className=" text-center">
            <div>
              <div className="m-4">
                Available old Stock: {parseInt(sparePart.quantity)}
              </div>
              {/*  <div className="m-4">
                Available new Stock:
                {totalStock}
              </div> */}
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className=" form-group  row">
              <label className="col-sm-4">New stock quantity:</label>

              <div className="col-sm-8">
                <input
                  className="form-control"
                  type="number"
                  onChange={handleChange}
                  name="quantity"
                  //value={quantity}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-5">
              <buttton onClick={handleClose} className="btn btn-success">
                Close
              </buttton>
              <button type="submit" className="btn btn-success">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewStock;

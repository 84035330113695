import React, { useEffect, useState } from "react";
import "../Styles/Summary.css";
import UseModal from "../components/Modal/UseModal";
import DeleteModal from "../components/Modal/DeleteModal";
import Title from "../components/title/Title";
import EditModal from "../components/Modal/EditModal";
import Modal from "../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import BranchUnavailable from "../components/others/BranchUnavailable";
import AddNewStock from "../components/stock/AddNewStock";

function Management({ branch, spareParts, updateData }) {
  const [searchField, setSearchField] = useState("");
  const [filteredSpareParts, setFilteredSpareParts] = useState([]);
  const { isShowingAdd, toggleAdd } = UseModal();
  const [isShowingAddNewStock, setIsShowingAddNewStock] = useState(true);
  const [sparePart, setSparePart] = useState({});
  useEffect(() => {
    let tempFilterdData = spareParts;

    if (searchField) {
      tempFilterdData = spareParts?.filter((element) => {
        return (
          element?.name?.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
        );
      });
    }

    tempFilterdData.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    setFilteredSpareParts(tempFilterdData);
  }, [searchField, spareParts]);
  const handleSearch = (searchvalue) => {
    setSearchField(searchvalue);
  };
  const handleUpdateNewStock = (selectedSpt) => {
    setSparePart(selectedSpt);
    toggleAddNewStock();
  };

  const toggleAddNewStock = () => {
    setIsShowingAddNewStock(!isShowingAddNewStock);
  };
  return (
    <>
      {isShowingAddNewStock ? (
        <div className="mt-3 h-100">
          <div className="side-container">
            <Title
              branch={branch}
              handleClick={toggleAdd}
              btn
              input
              handleSearch={handleSearch}
            />

            <Modal
              isShowingAdd={isShowingAdd}
              hide={toggleAdd}
              branch={branch}
              modalTitle="Add New Stock"
              spareParts={spareParts}
              updateData={updateData}
            />
            {branch ? (
              <div className="card table-responsive-sm w-100 p-3">
                <table className="table  fw-bold ">
                  <thead className="bg-success">
                    <tr>
                      <th> Image</th>
                      <th>Name</th>
                      {/* <th>Brand</th> */}
                      <th>Quantity</th>
                      <th>Price</th>
                      <th> Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-hover">
                    {filteredSpareParts?.map((sparePart, index) => (
                      <Frame
                        sparePart={sparePart}
                        key={sparePart.id + index}
                        branch={branch}
                        spareParts={spareParts}
                        updateData={updateData}
                        index={index}
                        handleUpdateNewStock={handleUpdateNewStock}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <BranchUnavailable
                messagedisplayed={"Branch not selected! please Select Branch "}
              />
            )}
          </div>
        </div>
      ) : (
        <AddNewStock
          sparePart={sparePart}
          toggleAddNewStock={toggleAddNewStock}
          spareParts={spareParts}
          updateData={updateData}
        />
      )}
    </>
  );
}

const Frame = ({
  sparePart,
  sparePart: { image, name, brand, quantity, price, id },
  spareParts,
  updateData,
  branch,
  handleUpdateNewStock,
}) => {
  const { isShowingEdit, toggleEdit, isShowingDelete, toggleDelete } =
    UseModal();

  return (
    <tr>
      <td>
        <div className="image-wrapper">
          <img src={image} alt="Spare Part" className="image-container" />
        </div>
      </td>
      <td onClick={() => toggleEdit(id)}>{name}</td>

      <td onClick={() => toggleEdit(id)}>{quantity}</td>
      <td onClick={() => toggleEdit(id)}>{price}</td>
      <td>
        <button
          onClick={() => handleUpdateNewStock(sparePart)}
          className="btn btn-outline-secondary me-3"
        >
          <i className="bi bi-plus" />
        </button>
        {/*   {!isShowingEdit && (
          <button
            onClick={() => toggleEdit(id)}
            className="btn btn-outline-secondary me-3"
          >
            <i className="bi bi-pencil" />
          </button>
        )} */}

        {isShowingEdit ? (
          <EditModal
            hide={toggleEdit}
            spareParts={spareParts}
            updateData={updateData}
            sparePart={sparePart}
            branch={branch}
          />
        ) : null}
        <button
          onClick={() => {
            toggleDelete(id);
          }}
          className="btn btn-outline-danger"
        >
          <i className="bi bi-trash" />
        </button>
        <DeleteModal
          isShowingDelete={isShowingDelete}
          branch={branch}
          id={id}
          hide={toggleDelete}
          updateData={updateData}
          spareParts={spareParts}
        />
      </td>
    </tr>
  );
};

export default Management;

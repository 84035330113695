import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCart, storeCart } from "../../Utils/local-storage";
import { showNotification } from "../../Utils/notification.util";

function EditCart({ togggleShowCartItems, soldItem, spareParts }) {
  const navigate = useNavigate();
  const [availableStock, setAvailabelStock] = useState(0);
  useEffect(() => {
    spareParts?.map((spt) => {
      if (spt?.id === soldItem.id) {
        setAvailabelStock(parseInt(spt.quantity));
      }
    });
    setItemSold(soldItem);
  }, []);
  const [itemsSold, setItemSold] = useState({
    VehicleBrand: "",
    id: "",
    image: "",
    name: " ",
    price: "",
    quantity: 1,
  });
  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "quantity") {
      if (e.target.value > availableStock) {
        showNotification("Quantity is more than available Stock", "");
      } else {
        setItemSold({
          ...itemsSold,
          [e.target.name]: value,
        });
      }
    } else {
      setItemSold({
        ...itemsSold,
        [e.target.name]: value,
      });
    }
  };
  const handleSubmit = () => {
    let tempCart = getCart();
    tempCart[itemsSold.id] = itemsSold;
    storeCart({ ...tempCart });
    togggleShowCartItems();
  };
  const handleCancel = () => {
    togggleShowCartItems();
  };
  return (
    <div>
      <div>
        <h1> {itemsSold.name}</h1>
      </div>
      <form className="w-50">
        <div className="form-group my-2">
          <label htmlFor="buyerName">Quantity</label>
          <input
            type="number"
            className="form-control"
            onChange={handleChange}
            name="quantity"
            value={itemsSold.quantity}
            required
          />
        </div>
        <div className="form-group my-2">
          <label htmlFor="buyerName">Price</label>
          <input
            type="number"
            className="form-control"
            id="buyersName"
            onChange={handleChange}
            name="price"
            value={itemsSold.price}
            required
          />
        </div>
      </form>
      <button
        onClick={handleSubmit}
        type="submit"
        className="btn btn-primary m-4 "
      >
        Submit
      </button>
      <button onClick={handleCancel} type="submit" className="btn btn-primary ">
        Cancel
      </button>
    </div>
  );
}

export default EditCart;

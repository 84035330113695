import React, { useEffect } from "react";
import { useState } from "react";

function Frame({ productPurchased }) {
  useEffect(() => {
    console.log({ productPurchased });
  }, []);
  return (
    <>
      {Object.values(productPurchased?.productPurchased)?.map((prdPurch) => (
        <tr>
          <td>{productPurchased.buyersName}</td>
          <td>{prdPurch.name}</td>
          <td>{prdPurch.quantity}</td>
          <td>{prdPurch.price}</td>
          <td>{productPurchased.Date} </td>
          <td>
            Ksh{}
            {parseInt(prdPurch.quantity) * parseInt(prdPurch.price)}
          </td>
        </tr>
      ))}
    </>
  );
}

function CreditFrame({ productPurchased }) {
  return (
    <>
      {Object.values(productPurchased?.productPurchased)?.map((prdPurch) => (
        <tr>
          <td>{productPurchased?.buyersName}</td>
          <td>{productPurchased?.totalAmount}</td>
          <td>{productPurchased?.initialDeposit}</td>
          <td>
            {parseInt(productPurchased?.totalAmount) -
              parseInt(productPurchased?.initialDeposit)}
          </td>
        </tr>
      ))}
    </>
  );
}

function CreditFrameCreditors({ productPurchased, toggleShowCreditorsItem }) {
  const [paid, setPaid] = useState(0);
  useEffect(() => {
    console.log(productPurchased);
    const sumOfCreditPaid = productPurchased?.creditAmountPaid?.reduce(
      (total, creditAmount) => total + parseInt(creditAmount.amount, 10),
      0
    );
    setPaid(sumOfCreditPaid);
  }, []);
  const handleEditCreditor = (e) => {
    e.preventDefault();
    toggleShowCreditorsItem(productPurchased);
  };
  return (
    <>
      <tr onClick={handleEditCreditor}>
        <td>{productPurchased?.buyersName}</td>
        <td>{productPurchased?.totalAmount}</td>
        <td>{paid}</td>
        <td>{parseInt(productPurchased?.totalAmount) - parseInt(paid)}</td>
      </tr>
    </>
  );
}

function FrameEditCredit({ productPurchased }) {
  return (
    <tr>
      <td>{productPurchased.name}</td>
      <td>{productPurchased.quantity}</td>
      <td>{productPurchased.price}</td>

      <td>
        Ksh{}
        {parseInt(productPurchased.quantity) * parseInt(productPurchased.price)}
      </td>
    </tr>
  );
}

export { Frame, CreditFrame, CreditFrameCreditors, FrameEditCredit };

import { db, doc } from "../../Utils/firebase";
import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import "../../Styles/Modal.css";
import "../../Styles/Summary.css";
import ReactDOM from "react-dom";
import {
  fetchFromDatabase,
  updateData,
  uploadToDatabase,
} from "../../service/DatabaseTransactions";
import { showNotification } from "../../Utils/notification.util";
import ModalComponent from "./ModalComponent";
import { v4 as uuid } from "uuid";
import { getCart, getDocumentId, storeCart } from "../../Utils/local-storage";

const SaleModal = ({
  sparePart,
  branch,
  isShowingSale,
  hide,
  spareParts,
  updateData: refresh,
  index,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [salesRecord, setSalesRecord] = useState([]);
  const [salesId, setSalesId] = useState("");

  const [state, setState] = useState({
    id: uuid(),
    buyersName: "",
    Date: moment().format("YYYY-MM-DD HH:mm"),
    quantity: quantity,
    price: 0,
    name: sparePart.productName,
  });

  const data = {
    quantity: sparePart.quantity - quantity,
  };

  const handleMinus = (e) => {
    e.preventDefault();
    if (quantity === 1) {
    } else {
      let newQuanity = quantity;
      setQuantity((newQuanity -= 1));
      setState((prevState) => ({
        ...prevState, // copy the previous state properties
        quantity: quantity, // update the name property
      }));
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let newQuanity = quantity;
    setQuantity((newQuanity += 1));
    setState((prevState) => ({
      ...prevState, // copy the previous state properties
      quantity: quantity, // update the name property
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    fetchFromDatabase(branch + "sales")
      .then((salesRecord) => {
        salesRecord?.forEach((element) => {
          setSalesId(element.id);
          setSalesRecord(element.data().data);
        });
      })
      .catch((error) => {});
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    let indexNum = 0;

    let tempSalesData = [...salesRecord];
    spareParts.map((spt, index) => {
      if (spt.id === sparePart.id) {
        indexNum = index;
      }
    });
    /*  let indexNum = spareParts.indexOf((spt) => spt.id === id); brings negative */
    if (spareParts[indexNum].quantity < quantity) {
      showNotification("The quantity is more than available Stock", "");
    } else {
      spareParts[indexNum].quantity = spareParts[indexNum].quantity - quantity;

      const docRef = doc(db, branch, getDocumentId());
      updateData({ data: spareParts }, docRef);

      tempSalesData.push(state);
      if (salesId === "" || salesId === undefined) {
        uploadToDatabase(branch + "sales", tempSalesData);
      } else {
        const salesRef = doc(db, branch + "sales", salesId);
        updateData({ data: tempSalesData }, salesRef);
      }

      showNotification("Sales Successfully made", "success");
      refresh();
      hide();
    }
  };

  const handleClose = () => {
    refresh();
    hide();
  };

  const handleAddToCart = () => {
    const tempCart = getCart() || {};
    sparePart.quantitySold = quantity;
    tempCart[sparePart.id] = sparePart;
    storeCart({ ...tempCart });

    hide();
  };

  return isShowingSale
    ? ReactDOM.createPortal(
        <React.Fragment>
          <ModalComponent
            handleClose={handleClose}
            handleSave={handleAddToCart}
            title={sparePart.name}
            btnTitle="Add To Cart"
          >
            <form onSubmit={handleAddToCart}>
              <div className="form-group">
                <label className="col-sm-4">Buyers Name</label>
                <div className="col-12 ">
                  <input
                    className="form-control"
                    type="text"
                    name="buyersName"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4">Selling Price</label>
                <div className="col-12">
                  <input
                    className="form-control"
                    type="number"
                    name="price"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-4">Quantity</label>
                <div className="col-12 d-flex align-items-center my-3">
                  <button
                    onClick={handleMinus}
                    className="btn btn-outline-danger btn-sm me-4"
                  >
                    <i className="bi bi-dash fs-4" />
                  </button>
                  <span className="me-3">{quantity}</span>
                  <button
                    onClick={handleAdd}
                    className="btn btn-outline-success btn-sm"
                  >
                    <i className="bi bi-plus fs-4" />
                  </button>
                </div>
              </div>
            </form>
          </ModalComponent>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SaleModal;

import { doc } from "firebase/firestore";
import { db } from "../Utils/firebase";
import {
  fetchFromDatabase,
  updateData,
  uploadToDatabase,
} from "./DatabaseTransactions";

const uploadToBbConditionally = (
  objectToBePushed,
  arrayData,
  documentId,
  dbname
) => {
  arrayData.push(objectToBePushed);
  if (documentId === "" || documentId === undefined) {
    uploadToDatabase(dbname, arrayData);
  } else {
    const documentReference = doc(db, dbname, documentId);
    updateData({ data: arrayData }, documentReference);
  }
};
const getDataAndDocumentId = (databaseName, storeTheId, setTheListItems) => {
  fetchFromDatabase(databaseName).then((salesList) => {
    salesList.forEach((arrayDocument) => {
      storeTheId(arrayDocument.id);
      setTheListItems(arrayDocument.data().data);
    });
  });
};

export { uploadToBbConditionally, getDataAndDocumentId };

import React from "react";
import "../../Styles/Modal.css";

export default function ModalComponent({
  children,
  handleClose,
  handleSave,
  title,
  btnTitle,
}) {
  return (
    <div className="main-wrapper">
      <div className="box col-12 col-lg-5">
        <div className="text-center fw-bold">{title}</div>
        <div>{children}</div>
        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-danger col-4"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-success col-4 "
            type="submit"
            onClick={handleSave}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";

import { useState } from "react";
import { getDataAndDocumentId } from "../../service/DatabaseTransactionHelper";
import { fetchFromDatabase } from "../../service/DatabaseTransactions";
import { getBranch, storeSalesId } from "../../Utils/local-storage";
import { CreditFrame, CreditFrameCreditors } from "../DatePicker/Frame";
import BranchUnavailable from "../others/BranchUnavailable";
import EditCreditor from "./EditCreditor";

function CreditorsBody() {
  const [showCreditorsItem, setShowCreditorsItems] = useState(true);
  const [soldItemsOnCredit, setSoldItemsOnCredit] = useState([]);
  const [soldItems, setSolditems] = useState([]);
  const [creditor, setCreditor] = useState();
  const [paid, setPaid] = useState(0);
  const fetchData = async () => {
    await getDataAndDocumentId(
      getBranch() + "sales",
      storeSalesId,
      setSolditems
    );
  };
  useEffect(() => {
    fetchData();
  }, [getBranch()]);
  useEffect(() => {
    if (soldItems) {
      const filteredSalesList = soldItems?.filter(
        (spt) => spt.credit === "true"
      );

      setSoldItemsOnCredit(filteredSalesList);

      console.log(filteredSalesList);
    }
  }, [soldItems]);

  const toggleShowCreditorsItem = (creditor) => {
    setShowCreditorsItems(!showCreditorsItem);
    setCreditor(creditor);
  };

  return (
    <>
      {showCreditorsItem ? (
        <div className="h-100">
          {getBranch() && soldItems ? (
            <table className="table table-hover ">
              <thead className="bg-success">
                <tr>
                  <th> Buyer's Name</th>
                  <th>Total Credit</th>
                  <th>Paid</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {soldItemsOnCredit?.map((sptOnCredit) => (
                  <CreditFrameCreditors
                    key={sptOnCredit.id}
                    productPurchased={sptOnCredit}
                    toggleShowCreditorsItem={toggleShowCreditorsItem}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <BranchUnavailable
              messagedisplayed={"Branch not selected! please Select"}
            />
          )}
        </div>
      ) : (
        <EditCreditor
          toggleShowCreditorsItem={toggleShowCreditorsItem}
          creditor={creditor}
          soldItems={soldItems}
          fetchData={fetchData}
        />
      )}
    </>
  );
}

export default CreditorsBody;

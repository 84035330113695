import React, { useState } from "react";
import "react-datetime/css/react-datetime.css";
import moment from "moment/moment";
import "../../Styles/Summary/DatePicker.css";
import { useEffect } from "react";
import { fetchFromDatabase } from "../../service/DatabaseTransactions";
import { showNotification } from "../../Utils/notification.util";
import { CreditFrame, Frame } from "./Frame";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { getBranch } from "../../Utils/local-storage";
import ExpenseFrame from "./ExpenseFrame";
import UseModal from "../Modal/UseModal";
/* import "../Styles/Summary.css"; */
import Title from "../title/Title";
import PickDateModal from "./PickDateModal";
function DatePicker({ branch, expensesList }) {
  const [filteredData, setFilteredData] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: moment().startOf("day").toDate(),
    endDate: new Date(),
    key: "selection",
  });
  const { isShowingPickDate, togglePickDate } = UseModal();
  const [totalSales, setTotalSales] = useState(0);
  const [totalCreditSale, setTotalCreditSale] = useState(0);
  const [totalCreditSettled, setTotalCreditSettled] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const fetchData = () => {
    fetchFromDatabase(branch + "sales").then((productsList) => {
      const specifiedData = productsList[0]
        ?.data()
        ?.data?.filter((item) =>
          moment(item.Date).isBetween(
            moment(selectionRange.startDate),
            moment(selectionRange.endDate),
            undefined,
            "[]"
          )
        );
      setFilteredData(specifiedData);
    });

    fetchFromDatabase(branch + "expenses").then((expensesList) => {
      let specifiedData = expensesList[0]
        ?.data()
        ?.data?.filter((item) =>
          moment(item.Date).isBetween(
            moment(selectionRange.startDate),
            moment(selectionRange.endDate),
            undefined,
            "[]"
          )
        );

      setExpenses(specifiedData);
    });

    const sumOfSales = filteredData.reduce(
      (total, salesAmount) => total + parseInt(salesAmount.price, 10),
      0
    );
  };
  const totalExp = () => {
    if (filteredData) {
      const sumOfSales = filteredData
        ?.filter((spt) => spt.credit === false)
        .reduce(
          (total, salesAmount) => total + parseInt(salesAmount.totalAmount, 10),
          0
        );

      const sumOfCreditSale = filteredData
        ?.filter((spt) => spt.credit === "true")
        .reduce(
          (total, salesAmount) => total + parseInt(salesAmount.totalAmount, 10),
          0
        );
      const sumOfCreditSettled = filteredData
        ?.filter((spt) => spt.credit === "true")
        .reduce(
          (total, salesAmount) =>
            total + parseInt(salesAmount?.initialDeposit, 10),
          0
        );

      setTotalCreditSale(sumOfCreditSale);
      setTotalCreditSettled(sumOfCreditSettled);
      setTotalSales(sumOfSales);
    }
    if (expenses) {
      const sumOfExpenses = expenses.reduce(
        (total, expensesAmount) => total + parseInt(expensesAmount.amount, 10),
        0
      );

      setTotalExpenses(sumOfExpenses);
    }
  };
  useEffect(() => {
    totalExp();
    console.log(filteredData);
  }, [filteredData, expenses]);
  useEffect(() => {
    fetchData();
  }, [selectionRange]);

  const handleClick = () => {
    togglePickDate();
  };
  return (
    <div className=" mt-3">
      <div className="side-container">
        <div>
          <Title
            branch={getBranch() + " Summary"}
            showBtn
            handleClick={handleClick}
          />
        </div>
      </div>

      <div className="card w-100 p3">
        {isShowingPickDate ? (
          <PickDateModal
            hide={togglePickDate}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
          />
        ) : null}
      </div>
      <div className="card table-responsive-sm w-100 p-3 mt-3">
        <b> Cash Sales</b>
        <table className="table table-hover ">
          <thead className="bg-success">
            <tr>
              <th> Buyer's Name</th>
              <th>Product</th>
              <th>Quantity</th>

              <th>Price</th>
              <th> Date</th>
              <th> Total</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              ?.filter((productPaid) => productPaid?.credit === false)
              ?.map((productPaid) => (
                <Frame productPurchased={productPaid} />
              ))}

            <tr>
              <td>{<b>Total</b>}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{<b style={{ color: "red" }}>Ksh {totalSales}</b>}</td>
            </tr>
          </tbody>
        </table>

        <b> Credit Sales</b>
        <table className="table table-hover ">
          <thead className="bg-success">
            <tr>
              <th> Buyer's Name</th>
              <th>Total Credit</th>
              <th>Paid</th>
              <th>Balance</th>
            </tr>
          </thead>

          <tbody>
            {filteredData
              ?.filter((productPaid) => productPaid?.credit === "true")
              ?.map((productPaid) => (
                <CreditFrame productPurchased={productPaid} />
              ))}

            <tr>
              <td>
                {" "}
                <b>Total</b>
              </td>
              <td>{totalCreditSale}</td>
              <td>{totalCreditSettled}</td>
              <td>{totalCreditSale - totalCreditSettled}</td>
            </tr>
          </tbody>
        </table>

        <b>Expenses</b>
        <table className="table table-hover mt-4 ">
          <thead className="bg-success">
            <tr>
              <th> Name</th>
              <th>Purpose</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>

          <tbody>
            {expenses?.map((expensesDetails) => (
              <ExpenseFrame expensesDetails={expensesDetails} />
            ))}
            <tr>
              <td>{<b>Total</b>}</td>
              <td></td>
              <td></td>
              <td>{<b style={{ color: "red" }}> Ksh {totalExpenses}</b>}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DatePicker;

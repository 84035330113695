import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CreateUser from "../components/Settings/CreateUser.jsx";
import {
  fetchFromDatabase,
  updateData,
} from "../service/DatabaseTransactions.jsx";
import { db, doc } from "../Utils/firebase.js";
import { v4 as uuid } from "uuid";
function Settings() {
  /*  const [spareParts, setSpareParts] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const handleClick = () => {
    const docRef = doc(db, "nakuru", documentId);
    const tempData = [...spareParts];
    tempData.forEach((Element) => {
      if (Element.quantity === "") {
        Element.quantity = 0;
      } else {
        Element.quantity = parseInt(Element.quantity, 10);
      }
    });
    updateData({ data: tempData }, docRef);
  };

  useEffect(() => {
    fetchFromDatabase("nakuru").then((Element) => {
      Element.forEach((data) => {
        setSpareParts(data.data().data);
        setDocumentId(data.id);
      });
    }, []);
  }); */

  return (
    <div>
      <CreateUser />
    </div>
  );
}

export default Settings;

import React from "react";
import "../../Styles/Settings/createUser.css";
import AddUser from "../Modal/AddUser";
import UseModal from "../Modal/UseModal";
function CreateUser() {
  const { isShowingAddUser, toggleAddUser } = UseModal();
  return (
    <div className="container">
      <h1>User Operation</h1>
      <div className="buttons-container">
        <button onClick={toggleAddUser}>Add User</button>
        <AddUser hide={toggleAddUser} isShowingAddUser={isShowingAddUser} />
        <button>Delete User</button>
      </div>
    </div>
  );
}

export default CreateUser;

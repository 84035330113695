import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/Modal.css";

import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../Utils/firebase";
import { useAuthValue } from "../Utils/AuthContext";
import { showNotification } from "../Utils/notification.util";
import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setTimeActive } = useAuthValue();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (!auth.currentUser.emailVerified) {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              setTimeActive(true);
              navigate("/verify-email");
            })
            .catch((err) => alert(err.message));
        } else {
          showNotification("Login successfuly", "success");
          navigate("/");
        }
      })
      .catch((err) => {
        setError(err.message);
        showNotification(err.message, "danger");
      });
  };

  return (
    <div className="main">
      <div className="card col-12 col-lg-3 d-flex flex-column justify-content-center  card-container ">
        <div className="card-body p-3">
          <div className="text-center my-3 fw-bold">EverFlow</div>
          {error && <div className="">{error}</div>}
          <form onSubmit={login} name="" className="form-group">
            <div className="col-12 my-3">
              <input
                className="form-control"
                type="email"
                value={email}
                required
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-12 my-3 password-input ">
              <input
                className="form-control "
                type={showPassword ? "text" : "password"}
                value={password}
                required
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <span className="password-toggle">
                <i
                  className="bi bi-eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </span>
            </div>

            <button className="btn btn-primary text-white w-100 ">
              Log in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;

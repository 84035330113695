import { updateDoc } from "firebase/firestore";
import { db, getDocs, doc } from "../Utils/firebase";
import { addDoc, collection } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { showNotification } from "../Utils/notification.util";
import { getAuth, updateProfile } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
const updateData = (data, docRef) => {
  console.log({ docRef });
  updateDoc(docRef, data)
    .then(() => {
      return true;
    })
    .catch((error) => {
      showNotification("An error Occurred", "");
      console.log(error);
      return false;
    });
};

const uploadToDatabase = async (database, data) => {
  console.log(database);
  if (!database) {
    return;
  }

  await addDoc(collection(db, database), {
    data,
  });
};

const fetchFromDatabase = async (databaseName) => {
  return getDocs(collection(db, databaseName))
    .then((result) => {
      const newInfo = [];
      result.forEach((data) => {
        newInfo.push(data);
      });

      return newInfo;
    })
    .catch((error) => {
      console.log(error);
    });
};

const deleteData = async (databaseName, dataId) => {
  deleteDoc(doc(db, databaseName, dataId))
    .then(() => {
      showNotification("Deleted Successfully", "success");
    })
    .catch((error) => {
      showNotification("An Error Occured", "warning");
    });
};

const addRoles = (user, currentUser) => {
  updateProfile(currentUser, {
    displayName: user,
  })
    .then(() => {
      showNotification("Role added Successfully", "success");
    })
    .catch((error) => {
      console.log("this the error that occored", error);
      showNotification(
        "An errror occured while updating the profile",
        "warning"
      );
    });
};

const createUser = (role, email, phoneNumber, password) => {
  const activeUser = new getAuth();
  createUserWithEmailAndPassword(activeUser, email, password)
    .then((userCredintials) => {
      showNotification(role + "User succesfully Created", "success");
      addRoles(role, activeUser.currentUser);

      // if (activeUser) {
      //   activeUser.reauthenticateWithCredential(
      //     auth.EmailAuthProvider.credential(
      //       activeUser.email,
      //       activeUser.password
      //     )
      //   );
      // }
    })
    .catch((error) => {
      console.log(error);
      showNotification("an error Occured while creating a user", "warning");
    });
};
export {
  updateData,
  uploadToDatabase,
  fetchFromDatabase,
  deleteData,
  addRoles,
  createUser,
};

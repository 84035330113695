import React, { useEffect, useState } from "react";
import "../Styles/Summary.css";
import UseModal from "../components/Modal/UseModal";
import SaleModal from "../components/Modal/SaleModal";
import Title from "../components/title/Title";
import { showNotification } from "../Utils/notification.util";
import { getCart, storeCart } from "../Utils/local-storage";
import BranchUnavailable from "../components/others/BranchUnavailable";

function Stock({ branch, spareParts, updateData, updateCart }) {
  const [searchField, setSearchField] = useState("");
  const [filteredSpareParts, setFilteredSpareParts] = useState([]);

  useEffect(() => {
    let tempFilterdData = [];
    if (searchField) {
      tempFilterdData = spareParts.filter((element) => {
        return (
          element?.name?.toLowerCase().indexOf(searchField.toLowerCase()) >= 0
        );
      });
      setFilteredSpareParts(tempFilterdData);
    } else {
      setFilteredSpareParts(spareParts);
    }
  }, [searchField, spareParts]);

  const handleSearch = (searchvalue) => {
    setSearchField(searchvalue);
  };

  return (
    <div className="mt-3 h-100">
      <div className="side-container">
        <div className="">
          <Title branch={branch} input handleSearch={handleSearch} />
        </div>

        {branch ? (
          <div className="card w-100 p-3">
            <div className="card-body table-responsive">
              <table className="table table-hover fw-bold ">
                <thead className="bg-success">
                  <tr>
                    <th> Image</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody className="overflow-auto">
                  {filteredSpareParts?.map((element, index) => (
                    <Frame
                      key={index}
                      sparePart={element}
                      branch={branch}
                      updateData={updateData}
                      index={index}
                      spareParts={spareParts}
                      updateCart={updateCart}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <BranchUnavailable
            messagedisplayed={"Branch not selected! please Select Branch "}
          />
        )}
      </div>
    </div>
  );
}

const Frame = ({
  sparePart,
  branch,
  updateData,
  index,
  spareParts,
  updateCart: refresh,
}) => {
  const [cart, setCart] = useState([]);
  const { isShowingSale, toggleSale } = UseModal();

  useEffect(() => {
    setCart(getCart());
  }, []);
  const handleAddToCart = () => {
    const tempCart = getCart();

    if (tempCart[sparePart.id]) {
      showNotification("Item already Exist in the Cart", "", "");
    } else {
      console.log({ sparePart, tempCart });
      tempCart[sparePart.id] = {
        ...sparePart,
        quantity: 1,
      };
      storeCart(tempCart);
      setCart({ ...tempCart });
    }
    refresh();
  };

  const removeFromCart = () => {
    const tempCart = getCart();
    delete tempCart[sparePart.id];
    storeCart(tempCart);
    setCart({ ...tempCart });
    refresh();
  };

  return (
    <tr>
      <td>
        <div className="image-wrapper">
          <img
            src={sparePart.imgUrl}
            alt="Spare Part"
            className="image-container"
          />
        </div>
      </td>
      <td>{sparePart.name}</td>
      <td>{sparePart.price}</td>
      <td>
        {cart[sparePart.id] ? (
          <button className="btn btn-danger" onClick={removeFromCart}>
            Remove from cart
          </button>
        ) : sparePart.quantity > 0 ? (
          <button onClick={handleAddToCart} className="btn btn-success">
            Add To Cart
          </button>
        ) : (
          <label className="bg-warning">Out Of Stock</label>
        )}
        <SaleModal
          sparePart={sparePart}
          branch={branch}
          isShowingSale={isShowingSale}
          hide={toggleSale}
          spareParts={spareParts}
          updateData={updateData}
          index={index}
        />
      </td>
    </tr>
  );
};
export default Stock;

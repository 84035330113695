import React from "react";
import { DateRangePicker } from "react-date-range";
import ReactDOM from "react-dom";
import ModalComponent from "../Modal/ModalComponent";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

function PickDateModal({ hide, selectionRange, setSelectionRange }) {
  const handleClose = () => {
    hide();
  };
  const handleSave = (e) => {
    e.preventDefault();
    hide();
  };
  return ReactDOM.createPortal(
    <React.Fragment>
      <ModalComponent
        handleClose={handleClose}
        btnTitle="Save"
        title={"Pick Date"}
        handleSave={handleSave}
      >
        <div className="card table-responsive-sm w-100 p-3 mt-3">
          <DateRangePicker
            onChange={(item) => {
              setSelectionRange(item.selection);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[selectionRange]}
            direction="horizontal"
          />
        </div>
      </ModalComponent>
    </React.Fragment>,
    document.body
  );
}

export default PickDateModal;

import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../../Styles/Modal.css";
import "../../Styles/Summary.css";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
  db,
  doc,
} from "../../Utils/firebase";
import { updateData } from "../../service/DatabaseTransactions";
import ModalComponent from "./ModalComponent";
import { useEffect } from "react";
import { getDocumentId } from "../../Utils/local-storage";

const EditModal = ({
  hide,
  spareParts,
  updateData: refresh,
  sparePart,
  branch,
}) => {
  const storage = getStorage();
  const docRef = doc(db, branch, getDocumentId());
  const [state, setState] = useState({
    id: "",
    name: "",
    price: 0,
    image: "",
    quantity: 0,
  });

  useEffect(() => {
    if (sparePart) {
      setState(
        {
          ...sparePart,
        },
        []
      );
    }
  }, []);

  const handleChange = (evt) => {
    evt.preventDefault();

    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* let indexNum = spareParts.indexOf((spt) => spt.id === state.id); */
    let indexNum = 0;
    spareParts?.map((spt, index) => {
      if (spt.id === state.id) {
        indexNum = index;
      }
    });
    let tempData = [...spareParts];

    tempData[indexNum] = state;

    updateData({ data: tempData }, docRef);
    refresh();

    hide();
  };

  const handleFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    const metadata = {
      contentType: "image/jpeg",
    };

    const storageRef = ref(storage, "SpareParts/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setState((prevState) => ({
            ...prevState, // copy the previous state properties
            image: downloadURL, // update the name property
          }));
        });
      }
    );
  };
  const handleClose = () => {
    hide();

    refresh();
  };
  return ReactDOM.createPortal(
    <React.Fragment>
      <ModalComponent
        handleClose={handleClose}
        handleSave={handleSubmit}
        btnTitle="Save"
        title={"Edit"}
      >
        <form onSubmit={handleSubmit}>
          <div className=" form-group my-2">
            <label className="col-sm-4 ">Spare Part Name</label>
            <div className="col-12">
              <input
                className="form-control"
                type="text"
                onChange={handleChange}
                name="name"
                value={state.name}
              />
            </div>
          </div>

          {/*  <div className="form-group  my-2">
                <label className="col-sm-4  ">Vehicle Brand</label>
                <div className="col-12 ">
                  <select
                    className="form-control"
                    name="VehicleBrand"
                    value={state.VehicleBrand}
                    onChange={handleChange}
                  >
                    {vehicle_brands.map((option) => (
                      <option key={option.value}>{option.value}</option>
                    ))}
                  </select>
                </div>
              </div>
            */}
          <div className=" form-group my-2">
            <label className="col-sm-4"> Price</label>
            <div className="col-12">
              <input
                className="form-control"
                type="number"
                value={state.price}
                onChange={handleChange}
                name="price"
              />
            </div>
          </div>

          <div className=" form-group my-2">
            <label className="col-sm-4 "> Image</label>
            <div className="col-12">
              <input
                type="file"
                onChange={handleFile}
                name="image"
                className="form-control"
              />
            </div>
          </div>

          <div className=" form-group my-3">
            <label className="col-sm-4"> Quantity </label>
            <div className="col-12">
              <input
                className="form-control"
                type="number"
                value={state.quantity}
                name="quantity"
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    </React.Fragment>,
    document.body
  );
};

export default EditModal;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { uploadToBbConditionally } from "../../../service/DatabaseTransactionHelper";
import { updateData } from "../../../service/DatabaseTransactions";
import { doc } from "firebase/firestore";
import { db } from "../../../Utils/firebase";
import { getBranch, getSalesId } from "../../../Utils/local-storage";
import { showNotification } from "../../../Utils/notification.util";
import { useNavigate } from "react-router-dom";

function Payment({
  toggleShowPayment,
  creditor,
  soldItems,
  fetchData: refresh,
}) {
  const [creditPaid, setCreditPaid] = useState(0);
  const [creditorDetails, setCreditoeDetails] = useState({ ...creditor });
  const [Paymentamount, setPaymentSettled] = useState(0);
  const today = moment().format("YYYY-MM-DD HH:mm");
  const [creditPayment, setCreditPayment] = useState({
    date: today,
    amount: 0,
    paymentNumber: 0,
  });
  const docRef = doc(db, getBranch() + "sales", getSalesId());
  const navigate = useNavigate();
  useEffect(() => {
    console.log(getSalesId());
    const sumOfCreditPaid = creditor?.creditAmountPaid?.reduce(
      (total, creditAmount) => total + parseInt(creditAmount.amount, 10),
      0
    );
    setCreditPaid(sumOfCreditPaid);
    console.log({ sumOfCreditPaid });
  }, [creditorDetails]);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value <= parseInt(creditor.totalAmount, 10) - creditPaid) {
      setPaymentSettled(value);
      setCreditPayment({ ...creditPayment, amount: value });
    } else {
      showNotification("amount exceeds the amount owed");
    }
  };

  useEffect(() => {
    setCreditoeDetails({ ...creditor });
    {
      console.log(creditor);
    }
  }, []);

  const updateCreditorsPayment = () => {
    let paymentProgress = [...creditorDetails?.creditAmountPaid];
    let monitor = creditorDetails.CreditPaymentNumber;
    setCreditPayment({
      ...creditPayment,
      date: today,
      paymentNumber: monitor + 1,
    });

    paymentProgress.push(creditPayment);
    console.log(paymentProgress);
    creditorDetails.creditAmountPaid = paymentProgress;
    setCreditoeDetails({
      ...creditorDetails,
      CreditPaymentNumber: monitor + 1,
    });
  };

  const handleSubmit = () => {
    if (Paymentamount == parseInt(creditor.totalAmount, 10) - creditPaid) {
      creditorDetails.credit = "false";
      console.log("I am here");
    }

    updateCreditorsPayment();
    let indexNum = 0;
    soldItems?.forEach((spt, index) => {
      if (spt.id === creditorDetails.id) {
        indexNum = index;
      }
    });
    let tempData = [...soldItems];
    tempData[indexNum] = creditorDetails;
    console.log({ docRef });
    updateData({ data: tempData }, docRef);

    showNotification("payment made successfully", "success");
    refresh();
    toggleShowPayment();
  };
  return (
    <div>
      <div>
        <h1> {creditor.buyersName}Credit Payment</h1>
      </div>
      <div>Amount Owed :{parseInt(creditor.totalAmount, 10) - creditPaid}</div>
      <div>
        Amount Remaining:{" "}
        {parseInt(creditor.totalAmount, 10) - creditPaid - Paymentamount}{" "}
      </div>
      <form className="w-50">
        <div className="form-group my-2">
          <label htmlFor="buyerName">Payment Amount</label>
          <input
            type="number"
            className="form-control"
            onChange={handleChange}
            name="amount"
            value={Paymentamount}
            required
          />
        </div>
      </form>

      <button
        onClick={() => toggleShowPayment()}
        type="submit"
        className="btn btn-primary "
      >
        Back
      </button>
      <button
        onClick={handleSubmit}
        type="submit"
        className="btn btn-primary m-4 "
      >
        Submit
      </button>
    </div>
  );
}

export default Payment;

export function storeDocumentId(documentId) {
  localStorage.setItem("documentId", documentId);
}

export function getDocumentId() {
  return localStorage.getItem("documentId");
}
export function storeBranch(branch) {
  localStorage.setItem("branch", branch);
}
export function getBranch() {
  return localStorage.getItem("branch");
}

export function storeExpensesId(expensesId) {
  return localStorage.setItem("expensesId", expensesId);
}
export function getExpensesId() {
  return localStorage.getItem("expensesId");
}

export function storeCart(cart) {
  return localStorage.setItem("cart", JSON.stringify(cart));
}
export function getCart() {
  return JSON.parse(localStorage.getItem("cart"));
}

export function setRole(role) {
  localStorage.setItem("role", role);
}
export function getRole() {
  return localStorage.getItem("role");
}

export function storeSalesId(documentId) {
  localStorage.setItem("salesId", documentId);
}
export function getSalesId() {
  return localStorage.getItem("salesId");
}

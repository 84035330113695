import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/title/Title";
import DatePicker from "../components/DatePicker/DatePicker";
import BranchUnavailable from "../components/others/BranchUnavailable";

function Summary({ branch, spareParts, expensesList }) {
  return (
    <div className="h-100">
      {branch ? (
        <div className="h-100">
          <DatePicker
            branch={branch}
            spareParts={spareParts}
            expensesList={expensesList}
          />
        </div>
      ) : (
        <BranchUnavailable
          messagedisplayed={"Branch not selected! please Select Branch "}
        />
      )}
    </div>
  );
}

export default Summary;

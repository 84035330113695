import React, { useEffect, useState } from "react";
import { doc } from "firebase/firestore";
import ReactDOM from "react-dom";
import "../../Styles/Modal.css";
import "../../Styles/Summary.css";
import { vehicle_brands } from "../../constants/vehicleTypes.constants";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
  db,
} from "../../Utils/firebase";
import {
  updateData,
  uploadToDatabase,
} from "../../service/DatabaseTransactions";
import { v4 as uuid } from "uuid";
import { getDocumentId } from "../../Utils/local-storage";
import moment from "moment/moment";

const Modal = ({
  isShowingAdd,
  hide,
  branch,
  modalTitle,
  spareParts,
  updateData: refresh,
}) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [specifyBrand, setSpecifyBrand] = useState(false);

  const storage = getStorage();

  const [state, setState] = useState({
    name: "",
    price: "",
    image: "",
    Quantity: 0,
    VehicleBrand: "",
  });

  useEffect(() => {
    if (state.VehicleBrand === "other") {
      setSpecifyBrand(true);
    }
  }, [state.VehicleBrand]);

  const uploadToDb = () => {
    const tempData = [...spareParts];

    tempData.push({
      id: uuid(),
      VehicleBrand: state.VehicleBrand,
      name: state.name,
      price: state.price,
      image: imgUrl,
      quantity: state.Quantity,
      stockProgress: [
        { data: moment().format("YYYY-MM-DD HH:mm"), quantity: state.Quantity },
      ],
    });
    if (getDocumentId() === "") {
      uploadToDatabase(branch, tempData);
    } else {
      const branchRef = doc(db, branch, getDocumentId());
      console.log(tempData);
      updateData({ data: tempData }, branchRef);
    }
  };

  const handleChange = (evt) => {
    evt.preventDefault();
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    uploadToDb();
    refresh();
    hide();
  };

  const handleFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    //const name = file[0].name;
    console.log(file.name);
    if (!file) return;

    const metadata = {
      contentType: "image/jpeg",
    };

    const storageRef = ref(storage, "SpareParts/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
          console.log("File available at", downloadURL);
        });
      }
    );
  };
  const handleClose = () => {
    refresh();
    hide();
  };
  return isShowingAdd
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="main-wrapper">
            <div className="box col-12 col-lg-4">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="text-center fw-bold m-3">{modalTitle}</div>
                  <div className="col-12 my-2">
                    <label className="col-sm-4 ">Spare Part Name</label>
                    <div className="col-12">
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleChange}
                        name="name"
                        value={state.name}
                      />
                    </div>
                  </div>

                  {/*  <div className="form-group col-12 my-2 ">
                    <label className="col-sm-4  ">Vehicle Brand</label>
                    <div className="col-12 ">
                      <select
                        name="VehicleBrand"
                        value={state.VehicleBrand}
                        onChange={handleChange}
                        className="form-control"
                      >
                        {vehicle_brands.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}

                  {/*  {specifyBrand ? (
                    <div className="form-group col-12 my-2 ">
                      <label className="col-sm-4  ">
                        Specify Vehicle Brand
                      </label>
                      <div className="col-12 ">
                        <input
                          name="VehicleBrand"
                          value={state.VehicleBrand}
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
               
 */}
                  <div className=" form-group col-12 my-2">
                    <label className="col-sm-4"> Price</label>
                    <div className="col-12">
                      <input
                        type="number"
                        value={state.price}
                        onChange={handleChange}
                        className="form-control"
                        name="price"
                      />
                    </div>
                  </div>
                  <div className=" form-group col-12 my-2">
                    <label className="col-sm-4 "> Image</label>
                    <div className="col-12">
                      <input
                        type="file"
                        onChange={handleFile}
                        name="image"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="form-group col-12 my-2">
                    <label className="col-sm-4 my-2"> Quantity </label>
                    <div className="col-12">
                      <input
                        type="number"
                        value={state.Quantity}
                        name="Quantity"
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <button
                      type="button"
                      className="btn btn-danger col-4"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button className="btn btn-success col-4 " type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;

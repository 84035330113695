import React from "react";
import CreditorsBody from "../components/creditors/CreditorsBody";

function Creditors() {
  return (
    <div className="h-100">
      <CreditorsBody />
    </div>
  );
}

export default Creditors;

import React from "react";
import EditCart from "./EditCart";

function CartFrame({ soldItem, handleRemove, index, togggleShowCartItems }) {
  const handleEditCart = (e) => {
    e.preventDefault();
    togggleShowCartItems(soldItem);
  };

  return (
    <tr>
      <td onClick={handleEditCart}>{soldItem.name}</td>
      <td onClick={handleEditCart}>{soldItem.quantity}</td>
      <td onClick={handleEditCart}>{soldItem.price}</td>
      <td onClick={handleEditCart}>
        {parseInt(soldItem.quantity) * parseInt(soldItem.price)}
      </td>
      <td>
        <button
          onClick={() => {
            handleRemove(soldItem.id);
          }}
          className="bt bg-success text-white"
        >
          Remove
        </button>
      </td>
    </tr>
  );
}

export default CartFrame;

import "bootstrap/dist/css/bootstrap.css";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  Router,
} from "react-router-dom";
import Item from "./pages/Item";
import { useState } from "react";
import { onAuthStateChanged, updateEmail } from "firebase/auth";
import { AuthProvider } from "./Utils/AuthContext";
import { useEffect } from "react";
import { auth } from "./Utils/firebase";
import Layout from "./pages/Layout";
import Stock from "./pages/Stock";
import Dashboard from "./pages/Dashboard";
import Add from "./pages/Add";
import VerifyEmail from "./userManagement/VerifyEmail";
import Register from "./userManagement/Register";
import Login from "./userManagement/Login";
import Management from "./pages/Management";
import Settings from "./pages/Settings";
import { toast, ToastContainer } from "react-toastify";
import Summary from "./pages/Summary";
import { fetchFromDatabase, updateData } from "./service/DatabaseTransactions";
import { monitorSalesChange } from "./Utils/monitorChange.util";
import {
  getBranch,
  getCart,
  getDocumentId,
  setRole,
  storeBranch,
  storeCart,
  storeDocumentId,
  storeExpensesId,
} from "./Utils/local-storage";
import Expenses from "./pages/Expenses";
import Cart from "./pages/Cart";
import CheckOut from "./components/cart/CheckOut";
import Creditors from "./pages/Creditors";
import Receipt from "./components/cart/receipts/Receipt";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState("");
  const [spareParts, setSpareParts] = useState([]);
  const [expensesList, setExpensesList] = useState([]);
  const [cartLength, setCartLength] = useState(0);

  const updateCart = () => {
    const tempCart = getCart();
    if (tempCart === null) {
      storeCart({});
    } else {
      setCartLength(Object.values(tempCart).length);
    }
  };
  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  const updateData = () => {
    storeDocumentId("");
    storeExpensesId("");
    setSpareParts([]);
    fetchFromDatabase(branch).then((productsList) => {
      productsList.forEach((element) => {
        storeDocumentId(element.id);
        console.log(getDocumentId());
        setSpareParts(element.data().data);
      });
    });
    fetchFromDatabase(getBranch() + "expenses").then((expenses) => {
      expenses.forEach((exparray) => {
        storeExpensesId(exparray.id);
        setExpensesList(exparray.data().data);
      });
    });
  };
  useEffect(() => {
    updateCart();
  }, []);
  useEffect(() => {
    if (!loading && currentUser && currentUser?.emailVerified && branch) {
      updateData();
    }
  }, [branch, monitorSalesChange]);
  useEffect(() => {
    setRole(currentUser?.displayName);
    console.log(currentUser?.displayName);
  }, []);

  if (loading) {
    return (
      <div>
        <h1>loading.....</h1>
      </div>
    );
  }

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="colored" />
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        {!loading && currentUser && currentUser?.emailVerified ? (
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Layout
                    role={currentUser.displayName}
                    cartLength={cartLength}
                    setBranch={setBranch}
                  />
                }
              >
                <Route
                  index
                  element={
                    <Dashboard
                      setBranch={setBranch}
                      role={currentUser.displayName}
                    />
                  }
                />
                <Route
                  path="expenses"
                  element={<Expenses expensesList={expensesList} />}
                />
                <Route
                  path="Stock"
                  element={
                    <Stock
                      branch={branch}
                      spareParts={spareParts}
                      updateData={updateData}
                      updateCart={updateCart}
                    />
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="management"
                  element={
                    <Management
                      branch={branch}
                      spareParts={spareParts}
                      updateData={updateData}
                    />
                  }
                />
                <Route path="/settings" element={<Settings />} />

                <Route
                  path="summary"
                  element={
                    <Summary
                      branch={branch}
                      spareParts={spareParts}
                      expensesList={expensesList}
                    />
                  }
                />
                <Route
                  path="cart"
                  element={
                    <Cart updateCart={updateCart} spareParts={spareParts} />
                  }
                />
                <Route
                  path="checkout"
                  element={
                    <CheckOut spareParts={spareParts} updateCart={updateCart} />
                  }
                />
                <Route path="creditors" element={<Creditors />} />
              </Route>
            </Routes>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </BrowserRouter>
        )}
      </AuthProvider>
    </>
  );

  /* (
    <BrowserRouter>
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        {currentUser && currentUser.emailVerified ? (
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/summary" element={<Summary />} />
            </Route>
            <Route
              path="item"
              element={
                <Item name={name} imgPath={imgPath} description={description} />
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={<Home setProductDetails={setProductDetails} />}
            >
             
            </Route>
            <Route
              path="item"
              element={
                <Item name={name} imgPath={imgPath} description={description} />
              }
            />
            <Route path="admin" element={<Admin />} />
          </Routes>
        )}
      </AuthProvider>
    </BrowserRouter>
  ); */
}

export default App;

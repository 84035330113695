import React from "react";
import { useState } from "react";
import "../../Styles/Modal.css";
import "../../Styles/Summary.css";
import ReactDOM from "react-dom";
import { createUser } from "../../service/DatabaseTransactions";
import { Navigate, useNavigate } from "react-router-dom";

const AddUser = ({ isShowingAddUser, hide }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    phoneNumber: "",
    role: "",
    password: "",
  });

  const roles = [
    { label: "select Role", key: "Seelct the role" },
    { label: "kisumu", key: "kisumu" },
    { label: "nakuru", key: "nakuru" },
    { label: "admin", key: "admin" },
  ];

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    createUser(state.role, state.email, state.phoneNumber, state.password);
    navigate("/settings");
    hide();
  };
  return isShowingAddUser
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="main-wrapper">
            <div className="container mt-2">
              <div className="fixed-top box">
                <div>
                  <div>
                    <h1>Add User</h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row component-wrapper">
                      <label className="col-sm-4">Email Address</label>
                      <div className="col-sm-8">
                        <input
                          type="email"
                          onChange={handleChange}
                          name="email"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row component-wrapper">
                      <label className="col-sm-4">Phone Number</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          onChange={handleChange}
                          name="phoneNumber"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row component-wrapper">
                      <label className="col-sm-4">Password</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          onChange={handleChange}
                          name="password"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row component-wrapper">
                      <label className="col-sm-4">Role</label>
                      <div className="col-sm-8">
                        <select name="role" onChange={handleChange} required>
                          {roles.map((option) => (
                            <option key={option.key}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <input type="submit" className="button-save" />
                    </div>
                  </form>
                  <button
                    type="button"
                    className="button-close "
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default AddUser;

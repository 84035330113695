import React, { useEffect, useState } from "react";
import AddExpenses from "../components/expenses/AddExpenses";
import Title from "../components/title/Title";
import "../Styles/Summary.css";
import UseModal from "../components/Modal/UseModal";
import { getBranch } from "../Utils/local-storage";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../Utils/notification.util";
import { fetchFromDatabase } from "../service/DatabaseTransactions";
import BranchUnavailable from "../components/others/BranchUnavailable";
function Expenses() {
  const [expensesList, setExpensesList] = useState([]);
  const [expensesRefId, setExpensesRefId] = useState("");
  const updateData = () => {
    fetchFromDatabase(getBranch() + "expenses").then((expenses) => {
      expenses.forEach((exparray) => {
        setExpensesRefId(exparray.id);
        setExpensesList(exparray.data().data);
      });
    });
  };

  useEffect(() => {
    {
      console.log(getBranch());
    }
    updateData();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(getBranch());
    if (getBranch() === null) {
      showNotification("Please select a region first", "");
      navigate("/");
    }
  }, []);

  const { isShowingAddExpense, toggleAddExpenses } = UseModal();
  const handleClick = () => {
    toggleAddExpenses();
  };
  return (
    <div className="mt-3 h-100">
      <div className="side-container">
        <div>
          <Title branch="Expenses " btn handleClick={handleClick} />
        </div>
        {getBranch() ? (
          <div className="card w-100 p3">
            {isShowingAddExpense ? (
              <AddExpenses
                hide={toggleAddExpenses}
                expensesList={expensesList}
                updateData={updateData}
                expensesRefId={expensesRefId}
              />
            ) : null}
          </div>
        ) : (
          <BranchUnavailable
            messagedisplayed={"Branch not selected! please Select Branch "}
          />
        )}
      </div>
    </div>
  );
}

export default Expenses;

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <footer className="bg-success">
      <p style={{ textAlign: "end" }}>
        {/* <button
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
          onClick={scrollToTop}
        >
          Back to top
        </button> */}
      </p>
      <p style={{ fontSize: "14px", textAlign: "center" }}>
        &copy; &middot;{" "}
        <Link style={{ color: "blue" }} to="/privacy">
          Privacy
        </Link>{" "}
        &middot;{" "}
        <Link style={{ color: "blue" }} to="/privacy">
          Terms
        </Link>
      </p>
      <p>Ex & New-Japan Genuine Spares</p>
      <div className="pt-5"></div>
    </footer>
  );
}

export default Footer;
